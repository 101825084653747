<template>
    <v-dialog
        :width="400"
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ $t('message.reserveBundle') + ": " + stockBundleNo }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="reserveForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="filteredBuyers"
                                :rules="[rules.buyer]"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Customer.title"
                                item-value="Customer.id"
                                solo
                                v-model="buyer_id"
                            >
                                <template v-slot:selection="{ item }">
                                    <div>{{ item.Customer.otsname != null ? item.Customer.otsname : item.Customer.title }}</div>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.save"
                    color="info"
                    small
                    @click="saveReserve()"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {log} from "Helpers/helpers";
import { api } from "Api/index";

export default {
    name: "ReserveStock",
    props: ['warehouseCountryId','stockId','stockBundleNo','reservedBuyerId','dialog','updateMode'],
    data(){
        return {
            buyer_id: null,
            dialogs: {
                error: false,
                error_message: ""
            },
            filteredBuyers: [],
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            rules: {
                buyer: v => !!v || this.$t('message.required')
            },
            validForm: true
        }
    },
    computed: {
        ...mapGetters('buyer',{
            allBuyers: 'allBuyers'
        }),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('buyer', {
            resetAllBuyers: 'resetAllBuyers',
            getAllActiveBuyers: 'getAllActiveBuyers'
        }),
        saveReserve() {
            if(this.$refs.reserveForm.validate()) {
                this.loading.save = true
                api
                    .put('/stocks/' + this.stockId + '/reserve', {
                        customer_id: this.buyer_id
                    })
                    .then(response => {
                        if(response.data.status == 'success') {
                            const selectedBuyer = this.filteredBuyers.find(buyer => buyer.Customer.id == this.buyer_id)
                            this.$toast.success(this.$t('message.successes.stockReserved'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.$emit('reserve-done', {
                                stockId: this.stockId,
                                reservedBuyer: selectedBuyer.Customer.otsname,
                                reservedBuyerId: selectedBuyer.Customer.id
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.stockNotReserved'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.save = false
                    })
                    .catch((e) => {
                        this.$toast.error(e,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.allBuyers.length == 0) this.getAllActiveBuyers()
                let countryId = 1 //default Malaysia
                if(this.warehouseCountryId == 'TH') countryId = 2 //Thailand
                if(this.warehouseCountryId == 'VN') countryId = 3 //Vietnam
                if(this.warehouseCountryId == 'ID') countryId = 4 //Indonesia
                this.filteredBuyers = this.allBuyers.filter(buyer => {
                    return buyer.Country.id == countryId
                })
                if(this.updateMode == true) {
                    this.buyer_id = this.reservedBuyerId
                } else {
                    this.buyer_id = null
                }
            } else {
                this.buyer_id = null
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        if(this.allBuyers.length == 0) this.getAllActiveBuyers()
    }
}
</script>

<style scoped>

</style>